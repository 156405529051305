<template>
  <div
    v-if="table"
    id="table-name-container"
    :class="{ 'smaller-margins': cartSummaryInPdaDesktop, 'smaller-padding': sections.length>0 && !cartSummaryInPdaDesktop }"
  >
    <div
      id="label-and-name"
      style="margin-bottom: 0px;"
      v-if="sections.length>0 && cartSummaryInPdaDesktop"
    >
      <div id="label">
        <h6>{{labels.tableOpenings.areaSection[language]}}</h6>
        <select v-model="selectedSection">
          <option value="ALL">Όλα</option>
          <option v-for="(section, index) in sections" :key="index" :value="section">{{section}}</option>
          <option :value="null">Υπόλοιπα</option>
        </select>
      </div>
    </div>

    <div id="sections-container" v-if="sections.length>0 && !cartSummaryInPdaDesktop">
      <button
        @click="setSelectedSection('ALL')"
        class="section-selection-button"
        :class="{ 'selected-section': selectedSection=='ALL' }"
      >Όλα</button>
      <button
        v-for="(section, index) in sections"
        :key="index"
        :value="section"
        @click="setSelectedSection(section)"
        class="section-selection-button"
        :class="{ 'selected-section': selectedSection==section }"
      >{{section}}</button>
      <button
        @click="setSelectedSection(null)"
        class="section-selection-button"
        :class="{ 'selected-section': selectedSection==null }"
      >Υπόλοιπα</button>
    </div>
    <div id="label-and-name">
      <div id="label">
        <h6>{{ tableType }}:</h6>
        <select @change="onTableSelect" v-if="tables" required v-model="selectedTableId">
          <option selected disabled :value="null">{{ beforeSelectTableText }}</option>
          <option
            v-for="availableTable in tables"
            :key="availableTable.id"
            :value="availableTable.id"
          >{{ availableTable.tableNumber }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableName",
  components: {},
  props: ["table", "cartSummaryInPdaDesktop"],
  data() {
    return {
      selectedTableId: null,
      selectedSection: "ALL"
    }
  },
  watch: {
    tables(newVal, oldVal) {
      if (newVal && newVal.length == 1) {
        let selectedTable = newVal[0];
        this.selectedTableId = selectedTable.id;
        this.$emit("table-select", selectedTable);
        this.$store.dispatch("setSelectedTable", selectedTable);
      } else if (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined) {
        let selectedTable = newVal.find(table => table.id == this.$route.query.tableId);
        if (selectedTable) {
          this.selectedTableId = selectedTable.id;
          this.$emit("table-select", selectedTable);
          this.$store.dispatch("setSelectedTable", selectedTable);
        }
      } else if (this.table && this.table.defaultTableSelected != null) {
        let selectedTable = newVal.find(table => table.id == this.table.defaultTableSelected);
        if (selectedTable) {
          this.selectedTableId = selectedTable.id;
          this.$emit("table-select", selectedTable);
          this.$store.dispatch("setSelectedTable", selectedTable);
        }
      }
    },
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    tableType() {
      return this.table.type == "HOTEL_ROOM"
        ? this.labels.table.room[this.language]
        : this.table.type == "BEACH_CHAIR" || this.table.type == "UMBRELLA_AT_HOTEL"
          ? this.labels.table.beachChair[this.language]
          : (this.table.type == "QR_SELF_SERVICE" || this.table.type == "SELF_SERVICE_POS" || this.table.type == "DELIVERY_TABLE")
            ? this.labels.table.spot[this.language]
            : this.labels.table.table[this.language];
    },

    sections() {
      return this.$store.getters.getTableFamilySections;
    },

    tables() {
      if (this.selectedSection == "ALL") {
        if (this.sections.length > 0) {
          this.selectedTableId = null;
          this.$emit("table-select", null);
          this.$store.dispatch("setSelectedTable", null);
        }
        return this.$store.getters.getAvailableTables;
      }

      if (this.sections.length > 0) {
        this.selectedTableId = null;
        this.$emit("table-select", null);
        this.$store.dispatch("setSelectedTable", null);
      }
      return this.$store.getters.getAvailableTables.filter(table => table.familySection == this.selectedSection);
    },

    beforeSelectTableText() {
      return this.table.type == "HOTEL_ROOM"
        ? this.labels.table.chooseYourRoom[this.language]
        : this.table.type == "BEACH_CHAIR" || this.table.type == "UMBRELLA_AT_HOTEL"
          ? this.labels.table.chooseYourBeachChair[this.language]
          : (this.table.type == "QR_SELF_SERVICE" || this.table.type == "SELF_SERVICE_POS" || this.table.type == "DELIVERY_TABLE")
            ? this.labels.table.chooseYourSpot[this.language]
            : this.$route.name == 'cart' ? this.labels.table.chooseYourTable[this.language] : this.labels.table.choose[this.language];
    },

  },
  methods: {
    onTableSelect() {
      let selectedTable = this.tables.find(table => table.id == this.selectedTableId);
      this.$emit("table-select", selectedTable);
      this.$store.dispatch("setSelectedTable", selectedTable);
    },
    setSelectedSection(section) {
      this.selectedSection = section;
    },
    resetSelected() {
      this.selectedSection = "ALL";
      setTimeout(() => {
        if (this.table && this.table.defaultTableSelected != null) {
          let selectedTable = this.tables.find(table => table.id == this.table.defaultTableSelected);
          if (selectedTable) {
            this.selectedTableId = selectedTable.id;
            this.$emit("table-select", selectedTable);
            this.$store.dispatch("setSelectedTable", selectedTable);
          }
        } else if (this.tables.length > 1) {
          this.selectedTableId = null;
          this.$emit("table-select", null);
          this.$store.dispatch("setSelectedTable", null);
        }
      }, 80);

    }
  },
  mounted() {
    if (this.tables.length == 1) {
      let selectedTable = this.tables[0];
      this.selectedTableId = selectedTable.id;
      this.$emit("table-select", selectedTable);
      this.$store.dispatch("setSelectedTable", selectedTable);
    } else if (this.tables.length > 1 && this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined) {
      let selectedTable = this.tables.find(table => table.id == this.$route.query.tableId);
      if (selectedTable) {
        this.selectedTableId = selectedTable.id;
        this.$emit("table-select", selectedTable);
        this.$store.dispatch("setSelectedTable", selectedTable);
      }
    } else if (this.table && this.table.defaultTableSelected != null) {
      let selectedTable = this.tables.find(table => table.id == this.table.defaultTableSelected);
      if (selectedTable) {
        this.selectedTableId = selectedTable.id;
        this.$emit("table-select", selectedTable);
        this.$store.dispatch("setSelectedTable", selectedTable);
      }
    }
  }
};
</script>

<style scoped>
#table-name-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

.smaller-margins {
  margin-bottom: 0px !important;
  padding: 4px 0 1px 0 !important;
}

.smaller-padding {
  padding: 2px 0 2px 0 !important;
}

.zero-margin-bottom {
  margin-bottom: 0px !important;
}

#label-and-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

#label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#label > h6 {
  font-size: 14px;
  font-weight: 600;
}

#label > h6 > span {
  cursor: pointer;
  color: #198b4a;
  /* color: #ed5005;  */
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
}

#label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#sections-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  margin-bottom: 12px;
}

.section-selection-button {
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  cursor: pointer;
  color: #198b4a;
  background-color: #ffffff;
  border: 1px solid #198b4a;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0px;
  margin-right: 6px;
  white-space: nowrap;
}

.selected-section {
  color: #ffffff !important;
  background-color: #198b4a !important;
}

select {
  display: flex;
  align-self: center;
  justify-self: center;
  -webkit-appearance: auto !important;
  box-shadow: inset 0px 0px 2px rgba(116, 135, 150, 0.2),
    inset 1px 1px 2px rgba(116, 135, 150, 0.36);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  margin-left: 7px;
  padding: 2px;
  color: #198b4a !important;
  font-weight: bold;
  background: #f7f7f7;
}
@media screen and (max-width: 800px) {
  select {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 300px) {
  select {
    font-size: 12px;
  }
}

option {
  color: #198b4a !important;
  font-weight: 600;
}
</style>